import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layout/main.vue"),
    children: [
      {
        path: "/",
        redirect: "/liveSource",
      },
      {
        path: "/liveSource",
        name: "liveSource",
        component: () => import("@/views/liveSource.vue"),
      },
      {
        path: "/channelDetail",
        name: "channelDetail",
        component: () => import("@/views/channelDetail.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const isLogin = window.localStorage.getItem("user_token") ? true : false;
//   console.log(isLogin)
//   if (!isLogin && to.path != "/login") {
//     next("/login")
//   } else {
//     next();
//   }
// })

export default router;
