import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueBus from "vue-bus";

Vue.config.productionTip = false;

Vue.use(VueBus);
//引入ant-design-vue
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);

import "@/assets/css/tool.css";

import "@/assets/font/iconfont.css";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
